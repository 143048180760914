body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

.app {
  text-align: center;
  padding: 20px;
}

.header {
  margin-bottom: 20px;
}

.header h1 {
  color: #007ac2;
  font-size: 24px;
  margin: 0;
}

.header h2 {
  font-size: 20px;
  margin: 5px 0;
}

.header p {
  font-size: 14px;
  color: #555;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.section {
  border: 1px solid #ccc;
  padding: 20px;
  width: 80%;
  max-width: 300px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.section h3 {
  font-size: 18px;
  margin: 0;
}

.modern-gauge {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

input[type="range"] {
  width: 80%;
}

.switches {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 80%;
  max-width: 300px;
}

.switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.switch label {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.switch button {
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 3px;
}

.switch button.on {
  background-color: #4caf50;
  color: white;
}

.switch button.off {
  background-color: #f44336;
  color: white;
}

